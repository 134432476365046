import React from 'react'
import './css/AhoraEs.css'

function AhoraEs() {
  return (
    <div className="contendor__AhoraEs">
        <div className="titulo__AhoraEs">
        ¡Ahora es tu momento!
        </div>
        <div className="texto__AhoraEs">
        Sabemos que es complejo dar el salto que te llevará al nivel profesional, pero si no es ahora, respónde a ti mismo ¿Cuándo?
        </div>
    </div>
  )
}

export default AhoraEs