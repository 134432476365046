import React from 'react'
import './css/Contacto.css'
import { FaWhatsapp,FaInstagram } from "react-icons/fa6";

function Contacto() {
  return (
    <div className="contendor__Contacto">
        <div className="informacion__Contacto">
            <div className="texto__Contacto">
                <div className="titulo__Contacto">
                EntramadosEC
                </div>
                <div className="subtitulo__Contacto">
                    <div className="subtitulo1__Contacto">
                    Deja de postergarlo
                    </div>
                    <div className="subtitulo2__Contacto">
                 y contáctanos AHORA

                    </div>
                </div>
                <div className="direccion__Contacto infor__Contacto">
                Ramírez Dávalos y Ulpiano Páez Quito - Ecuador
                </div>
                <div className="telefono__Contacto infor__Contacto">
                +593 995096748
                </div>
                <div className="correo__Contacto infor__Contacto">
                    contacto@entramados.ec
                </div>
            </div>
            <div className="boton__Contacto">
                <div className="whatsapp__Contacto">
                    <div className="textow__Contacto">
                    INICIA UNA CONVERSACIÓN
                    </div>
                    <div className="iconow__Contacto">
                    <FaWhatsapp />
                    </div>
                </div>

            </div>

        </div>
        <div className="instagram__Contacto">
            <div className="texto2__Contacto">
            Sigue nuestros proyectos en 
            </div>
            <div className="iconow__Cantacto">
            <FaInstagram />
            </div>
        </div>
        <div className="nada__Contacto">

        </div>
    </div>
  )
}

export default Contacto