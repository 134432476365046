import React from 'react'
import Imagen from '../images/Homeizq.png'
import './css/Home.css'
function Home() {
  return (
    <div className="contenedor__Home">
        <div className="informacion__Home">
            <div className="titulo__Home">
            EntramadosEC
            </div>
            <div className="slogans__Home">

            <div className="slogan__Home">
            Tejiendo
            </div>
            <div className="slogan__Home">
            soluciones
            </div>
            <div className="slogan__Home">
            digitales
            </div>
            </div>
            <div className="accesos__Home">
            </div>
            <div className="btnAcceso__Home">
               
                <button className="verservicios__Home btn__Home">
                    Ver servicios
                </button>
                <button className="iniciarconversacion__Home btn__Home">
                Iniciar conversación
                </button>
            </div>

        </div>
    
            <img src={Imagen} alt="imagen__Home" className="imagen__Home" />

    </div>
  )
}

export default Home