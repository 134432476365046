import React from 'react'
import './css/Landing.css'
import Home from './containers/Home'
import Presentation from './containers/Presentation'
import QueHacemos from './containers/QueHacemos'
import ComoHacemos from './containers/ComoHacemos'
import LoDicenEllos from './containers/LoDicenEllos'
import AhoraEs from './containers/AhoraEs'
import Contacto from './containers/Contacto'


function Landing() {
  return (
    <div className="contenedor__Landing">
        <div className="navbar__Landing">
        </div>
        <div className="Home__Landing">
        <Home/>
        </div>
        <div className="Presentacion__Landing">
        <Presentation/>
        </div>
        <div className="Quehacemos__Landing">
          <QueHacemos/>
        </div>
        <div className="ComoHacemos__Landing">
          <ComoHacemos/>
        </div>
        <div className="LoDicenEllos__Landing">
          <LoDicenEllos/>
        </div>
        <div className="AhoraEs__Landing">
          <AhoraEs/>
        </div>
        <div className="Contacto__Landing">
          <Contacto/>
        </div>
    </div>
  )
}

export default Landing