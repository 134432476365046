import React from 'react'
import './css/Presentation.css'
import Image from '../images/ImagenEscritorio.webp'

function Presentation() {
  return (
    <div className="contenedor__Presentation">
        <img src={Image} alt="Imagen de presentacion" className="imagen__Presentation" />
        <div className="informacion__Presentation">
            <div className="texto1__Presentation">
            Somos artesanos digitales expertos en el desarrollo de software, la gestión de redes sociales y la creación de contenido.
                </div>
                <div className="texto2__Presentation">
                Vamos a impulsar tu presencia digital!

                </div>
        </div>
    </div>
  )
}

export default Presentation