import React from 'react'
import './css/ComoHacemos.css'
import { FaCircle } from "react-icons/fa";

function ComoHacemos() {
  return (
    <div className="contendor__ComoHacemos">
        <div className="pregunta__ComoHacemos">
          <div className="titulo1__ComoHacemos">
        ¿Cómo  
        </div>
        <div className="titulo1__ComoHacemos">
        lo hacemos?
          </div>
        </div>
        <div className="respuestas__ComoHacemos">
            <div className="respuesta__ComoHacemos">
            <div className="titulorespuesta__ComoHacemos">
              <div className="icono__ComoHacemos">
              <FaCircle />
              </div>
            Desarrollo de software
            </div>
            <div className="texto__ComoHacemos">
            Transformamos tus ideas en soluciones digitales tangibles. Ya sea una aplicación personalizada o un sistema empresarial complejo, nuestro equipo de desarrollo de software crea soluciones eficientes y adaptadas a tus necesidades específicas.
            </div>
            </div>
            <div className="respuesta__ComoHacemos">
            <div className="titulorespuesta__ComoHacemos">
            <div className="icono__ComoHacemos">
              <FaCircle />
              </div>
            Diseño web
            </div>
            <div className="texto__ComoHacemos">
            Con un enfoque centrado en el usuario, creamos experiencias visuales cautivadoras que no solo atraen, sino que retienen. Desde páginas web impactantes hasta plataformas responsive, construimos la presencia online que tu empresa merece, combinando estética y funcionalidad de manera armoniosa.
            </div>
            </div>
            <div className="respuesta__ComoHacemos">
            <div className="titulorespuesta__ComoHacemos">
            <div className="icono__ComoHacemos">
              <FaCircle />
              </div>
            Marketing digital
            </div>
            <div className="texto__ComoHacemos">
            Desde la conceptualización de contenidos estratégicos hasta el despliegue de campañas publicitarias, nos destacamos en conectar tu marca con audiencias relevantes. Nuestro enfoque abarca desde el SEO hasta el Inbound Marketing, asegurando una presencia digital efectiva y un impacto duradero en tu público objetivo.
            </div>
            </div>

        </div>
    </div>
  )
}

export default ComoHacemos