import React from 'react'
import './css/LoDicenEllos.css'
import { RiDoubleQuotesL } from "react-icons/ri";
import FondoLoDicenEllos from '../images/FondoEllosDicen.png'

function LoDicenEllos() {
  return (
    <div className="contenedor__LoDicenEllos">
        <div className="imagen__LoDicenEllos">
        <img src={FondoLoDicenEllos} alt="" className='fondo__loDicenEllos'/>
        </div>
        <div className="informacion__LoDicenEllos">

        
        <div className="titulo__LoDicenEllos">
        Lo dicen ellos...
        </div>
        <div className="cuerpo__LoDicenEllos">
            <div className="respuesta__LoDicenEllos">
            <div className="encabezado__LoDicenEllos">
                <div className="icono__LoDicenEllos">
                <RiDoubleQuotesL />
                </div>
                <div className="barra__LodicenEllos">
                    <div className="partesup__LoDicenEllos">
                    </div>
                    <div className="parteinf__LoDicenEllos">   
                    </div>
                </div>
            </div>
            <div className="texto__LoDicenEllos">
            Boost your product and service's credibility by adding testimonials from your clients. People love recommendations so feedback from others who've tried it is invaluable.
            </div>
            <div className="persona__LoDicenEllos">
            - Natalia Lowe
            </div>
            </div>
            <div className="respuesta__LoDicenEllos">
            <div className="encabezado__LoDicenEllos">
                <div className="icono__LoDicenEllos">
                <RiDoubleQuotesL />
                </div>
                <div className="barra__LodicenEllos">
                    <div className="partesup__LoDicenEllos">

                    </div>
                    <div className="parteinf__LoDicenEllos">
                        
                    </div>
                </div>


            </div>
            <div className="texto__LoDicenEllos">
            Boost your product and service's credibility by adding testimonials from your clients. People love recommendations so feedback from others who've tried it is invaluable.
            </div>
            <div className="persona__LoDicenEllos">
            - Natalia Lowe
            </div>
            </div>
            <div className="respuesta__LoDicenEllos">
            <div className="encabezado__LoDicenEllos">
                <div className="icono__LoDicenEllos">
                <RiDoubleQuotesL />
                </div>
                <div className="barra__LodicenEllos">
                    <div className="partesup__LoDicenEllos">

                    </div>
                    <div className="parteinf__LoDicenEllos">
                        
                    </div>
                </div>


            </div>
            <div className="texto__LoDicenEllos">
            Boost your product and service's credibility by adding testimonials from your clients. People love recommendations so feedback from others who've tried it is invaluable.
            </div>
            <div className="persona__LoDicenEllos">
            - Natalia Lowe
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default LoDicenEllos