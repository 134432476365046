import React from 'react'
import './css/QueHacemos.css'
import { FaArrowDown } from "react-icons/fa6";
import Software from '../images/desarrolloSoftware.webp'
import Disenio from '../images/DisenioWeb.jpg'
import Marketing from '../images/marketingDigital.jpg'

function QueHacemos() {
  return (
    <div className="contenedor__QueHacemos">
        <div className="titulo__QueHacemos">
        ¿Qué hacemos en EntramadosEC?
        </div>
        <div className="texto__QueHacemos">
        Unimos tecnología, diseño y estrategia para impulsar tu presencia digital y lograr resultados excepcionales. Desarrollamos soluciones de software a medida, creamos experiencias web cautivadoras y ejecutamos estrategias de marketing digital efectivas para llevar tu marca al siguiente nivel.
        </div>
        <div className="cards__QueHacemos">
            <div className="card__Quehacemos">
                <img src={Software} alt="" />
                <div className="contenidocard__QueHacemos">
                    <div className="textocard__QueHacemos">
                    DESARROLLO DE SOFTWARE
                    </div>
                    <div className="abajo__QueHacemos">
                    <div className="icono__QueHacemos">
                    <FaArrowDown />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card__Quehacemos">
                <img src={Disenio} alt="" />
                <div className="contenidocard__QueHacemos">
                    <div className="textocard__QueHacemos">
                    DISEÑO WEB  
                    </div>
                    <div className="abajo__QueHacemos">
                        <div className="icono__QueHacemos">
                    <FaArrowDown />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card__Quehacemos">
                <img src={Marketing} alt="" />
                <div className="contenidocard__QueHacemos">
                    <div className="textocard__QueHacemos">
                    MARKETING DIGITAL
                    </div>
                    <div className="abajo__QueHacemos">
                    <div className="icono__QueHacemos">
                    <FaArrowDown />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default QueHacemos