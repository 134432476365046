import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from '../landing/Landing'

function Rutas() {
  return (
    <Routes>
        <Route path='/' element={<Landing/>}/>
    </Routes>
  )
}

export default Rutas